// USE "statsV3" MIXINS INSTEAD OF THIS

// This mixin is a V2 endpoint, wich get higher-level stats
// Because the V2 stats will change in the futur,
// We should migrate to new endpoint as much as possible

export default {
  methods: {
    // The param can be an identifiant, but this request only works with identifiant and
    // no other parameters of periode
    async getCceStatsData (filtreOrId, options = {}) {
      const { includeHisto = false, periode = 'SEMAINE' } = options
      try {
        const isId = typeof filtreOrId === 'string'
        if (isId) {
          const fromStore = this.$store.state.Exercices?.stats?.[filtreOrId]
          if (fromStore) {
            return fromStore
          }
        }

        const query = includeHisto
          ? require('@/graphql/queries/v2/analyse/statistiqueCalendrierCalendrierElements.gql')
          : require('@/graphql/queries/v2/analyse/statistiqueCalendrierCalendrierElementsData.gql')
        const filtre = isId ? { identifiants: [filtreOrId] } : filtreOrId
        const { data } = await this.$apollo.query({
          query,
          variables: { filtre, periode },
          fetchPolicy: 'network-only'
        })
        const jsonData = data?.statistiqueCalendrierCalendrierElements?.data
        const stats = jsonData ? JSON.parse(jsonData) : {}

        // populate stats infos
        stats.termineCount = stats.count ? stats.count : 0
        stats.avgNote = Number.isFinite(stats.avg) ? Math.round(stats.avg) + '%' : this.$t('partage.n-d')
        stats.minNote = Number.isFinite(stats.min) ? Math.round(stats.min) + '%' : this.$t('partage.n-d')
        stats.maxNote = Number.isFinite(stats.max) ? Math.round(stats.max) + '%' : this.$t('partage.n-d')

        if (includeHisto) {
          stats.historique = data?.statistiqueCalendrierCalendrierElements?.historique?.map(h => {
            return {
              ...JSON.parse(h.data),
              debut: h.debut,
              fin: h.fin
            }
          })
        }

        if (isId) {
          this.$store.commit('Exercices/addStat', { stats, id: filtreOrId })
        }

        return stats
      } catch (e) {
        console.error(e)
        return {}
      }
    }
  }
}
