export default {
  data () {
    return {
      tableauWidth: 0
    }
  },
  watch: {
    '$store.state.App.windowSize': {
      immediate: true,
      deep: true,
      handler (newVal) {
        this.checkGabaritWidth(newVal.width)
      }
    }
  },
  created () {
  },
  methods: {
    checkGabaritWidth (e) {
      if (window.document.getElementById('tableau')) {
        this.tableauWidth = window.document.getElementById('tableau').clientWidth
      }
    }
  }
}
