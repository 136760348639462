<script>
export default {
  name: 'deleteCalendarEvent',
  data () {
    return {
      loading: false,
      showError: false,
      deleteAll: false
    }
  },
  computed: {
    showDeleteEvent: {
      set (val) { this.$store.commit('Dialogs/setDeleteEvent', val) },
      get () { return this.$store.state.Dialogs.showDeleteEvent }
    },
    elemToDelete () {
      return this.$store.state.Dialogs.elemToDelete
    }
  },
  watch: {
  },
  methods: {
    reset () {
      this.code = null
      this.loading = false
      this.showError = false
    },
    async deleteEvent () {
      const elem = this.elemToDelete
      this.showError = false
      let all = true
      if (elem.identifiant) {
        const temp = await this.deleteElem(elem.identifiant)
        if (temp.destroyCalendrierElement) {
          all = false
          this.showError = true
        }
      } else {
        for (let i = 0; i < elem.data.length && all; i++) {
          const temp = await this.deleteElem(elem.data[i].id)
          if (!temp || temp.destroyCalendrierElement.errors) {
            all = false
          }
        }
      }
      if (all) {
        this.showDeleteEvent = false
        this.$store.dispatch('Exercices/fetchDevoirsRecent')
      } else {
        this.showError = true
      }
      this.$emit('removeEvent')
    },
    async deleteElem (id) {
      return this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/destroyCalendrierElement.gql'),
        variables: {
          id: id
        }
      }).then(({ data }) => {
        this.showDeleteEvent = false
        this.$store.commit('App/setShowSnackbarGlobal', true)
        this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: this.$t('alerte.succes'), description: this.$t('messages.devoir-supprime-success') })
        return data
      })
    }
  }
}
</script>
<template lang="pug">
v-dialog(v-model='showDeleteEvent', max-width='600',content-class='custom-dialog', persistent)
  v-card.light()
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button.white--text(@click='showDeleteEvent = !showDeleteEvent', @click.native='reset', depressed, fab, x-large, color='secondary')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title {{$t('action.supprimer')}} {{ (elemToDelete && elemToDelete.type === 'lecture') ? $t("lecture.liste-lecture-planif") : $t("calendrier.evenement") }}
    v-card-text.relative
      div.dialog-text.mb3(v-if='elemToDelete')
        div {{ $t('action.confirm-suppression') }} {{ (elemToDelete && elemToDelete.type === 'lecture') ? $t("lecture.cette-lecture") : $t("calendrier.cet-evenement")}} ?
        div.mv3.i.f4 {{elemToDelete.name}}
        div(v-if='elemToDelete.data && elemToDelete.data[0] && elemToDelete.data[0].elementsUsagers.total') {{elemToDelete.data[0].elementsUsagers.total}} {{$tc('eleves.eleve-concerne', elemToDelete.data[0].elementsUsagers.total)}}
        div(v-else-if='elemToDelete.data && elemToDelete.data.elementsUsagers && elemToDelete.data.elementsUsagers.total') {{elemToDelete.data.elementsUsagers.total}} {{$tc('eleves.eleve-concerne', elemToDelete.data.elementsUsagers.total)}}
    v-btn.white--text(@click='deleteEvent', color='error', block, x-large)
      span.f4 {{$t('action.supprimer')}}
</template>
<style lang='sass'>

</style>
