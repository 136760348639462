/*
import displayNote from '@/mixins/displayNote'
  mixins: [displayNote],
*/

export default {
  methods: {
    ratedNote (originalNote, options = {}) {
      const nid = this.$store.state.User.profile?.info?.salleClasseNotation?.id
      const notationIdOrTitre = nid !== undefined ? nid : null
      const defaultNote = options.defaultNote !== undefined ? options.defaultNote : originalNote
      const returnOnlyNote = options.returnOnlyNote !== undefined ? options.returnOnlyNote : true
      const suffix = options.suffix !== undefined ? options.suffix : ''
      const note = Number(originalNote?.toString()?.replaceAll(' ', '')?.replaceAll('%', ''))
      const output = { origin: originalNote, asNumber: note, note: defaultNote }

      // Convert note to Number
      if (Number.isNaN(note)) {
        if (originalNote !== null && originalNote !== this.$t('partage.n-d')) {
          console.warn('Trying to convert a note into formattedNote but the input was not an number', originalNote)
        }
        return returnOnlyNote ? output.note : output
      }

      // Find notation based on store and notationId
      const notation = this.$store.state.User?.notations?.find(n => {
        return n.id === notationIdOrTitre || n.titre === notationIdOrTitre
      })
      if (!notation) {
        if (notationIdOrTitre !== null) {
          console.warn('Trying to convert a note into formattedNote but no notation found for this id:', notationIdOrTitre)
        }
        return returnOnlyNote ? output.note : output
      }

      const interv = notation.intervalles.find(i => {
        return (i.maximum === 100 && note === 100) || (i.minimum <= note && i.maximum > note)
      })
      if (!interv) {
        console.warn('Trying to convert a note into formattedNote but no intervalles found for this note:', note)
        return returnOnlyNote ? output.note : output + suffix
      }

      output.note = interv.affichage
      return returnOnlyNote ? (output.note + suffix) : (output + suffix)
    },
    noteFormatter (note) {
      return this.ratedNote(note)
    }
  },
  computed: {
    myFavoriteNotationTitre () {
      return this.$store.state.User.profile?.info?.salleClasseNotation?.titre
    }
  }
}
