export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    parseStatus (status, note) {
      if (status) {
        if (status === 'Actif') {
          return {
            name: 'À faire',
            color: 'amber'
          }
        } else if (status === 'En cours') {
          return {
            name: 'En cours',
            color: 'success'
          }
        } else if (status === 'Approuve') {
          return {
            name: 'À faire - Vu',
            color: 'amber'
          }
        } else if (status === 'Archive') {
          if (note) {
            return {
              name: 'Complété',
              color: 'success'
            }
          } else {
            return {
              name: 'Incomplet',
              color: 'error'
            }
          }
        } else {
          return {
            name: status,
            color: 'error'
          }
        }
      } else {
        return {
          name: 'N/D',
          color: 'grey'
        }
      }
    },
    parseStatusDevoirs (status) {
      if (status) {
        if (status === 'Actif') {
          return {
            name: 'En cours',
            color: 'amber'
          }
        } else if (status === 'Archive') {
          return {
            name: this.$t('exercices.outdated'),
            color: 'success'
          }
        } else {
          return {
            name: status,
            color: 'error'
          }
        }
      } else {
        return {
          name: 'N/D',
          color: 'grey'
        }
      }
    }
  }
}
